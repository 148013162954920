"use strict";
var updateActive = function () {
    var anchors = document.querySelectorAll(".Site__Side a");
    anchors.forEach(function (a) { return a.classList.remove("active"); });
    var hash = window.location.hash;
    if (hash && hash.length > 1)
        anchors.forEach(function (a) {
            if (a.getAttribute("href") === window.location.hash)
                a.classList.add("active");
        });
};
document.querySelectorAll(".Site__Side a").forEach(function (a, _i, all) {
    var addActive = function (a, all) { return function () {
        return all.forEach(function (b) {
            return a === b ? a.classList.add("active") : b.classList.remove("active");
        });
    }; };
    a.addEventListener("click", addActive(a, all));
});
window.addEventListener("hashchange", updateActive);
updateActive();
